import React, { useMemo } from "react";
import {
  ConveyorBeltOutlinedRounded,
  EventNoteOutlinedRounded,
  InsightsRounded,
  LabProfileOutlinedRounded,
  LaundryOutlinedRounded,
  LocalMallOutlinedRounded,
  LogoutRounded,
  ShareLocationOutlinedRounded,
  StorefrontOutlinedRounded,
  useAuthentication,
  useUserInfo,
} from "@fifthsun/ui";
import { IconButton } from "@mui/material";
import { Settings as SettingsIcon } from '@mui/icons-material';
import { ItemType } from "antd/lib/menu/interface.js";
import { IssuesCloseOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { UserInfo } from "@fifthsun/ui/api/mauthra/oauth2";

export const useMenuItems = () => {
  const { authenticated } = useAuthentication();
  const user = useUserInfo();

  const authenticationMenuItem = useMemo<ItemType>(() => {
    const greeting = formatGreeting(authenticated, user);

    // TO DO: Need a login icon
    return {
      label: <span>{greeting} &nbsp; <IconButton><LogoutRounded /></IconButton></span>,
      key: authenticated ? "logout" : "login"
    };
  }, [authenticated, user]);

  const menuItems = useMemo<ItemType[]>(() => {
    return [
      ..._menuItems,
      { type: "divider", style: { flex: 1, order: _menuItems.length } },
      authenticationMenuItem
    ];
  }, [authenticationMenuItem]);

  return menuItems;
};

const formatUserIdentifier = (user: UserInfo | undefined) => {
  if (!user) {
    return undefined;
  } else if (!isEmpty(user.lastName) && !isEmpty(user.firstName)) {
    return `${user.lastName}, ${user.firstName}`; 
  } else if (!isEmpty(user.email)) {
    return user.email;
  } else if (!isEmpty(user.firstName)) {
    return user.firstName;
  } else if (!isEmpty(user.lastName)) {
    return user.lastName;
  } else if (!isEmpty(user.username)) {
    return user.username;
  } 
  return "<unknown>";
   
};

const formatGreeting = (authenticated: boolean, user: UserInfo | undefined) => {
  if (!authenticated) {
    return <span><b>Login</b></span>;
  } else if (!user) {
    return <span><b>Logout</b></span>;
  } 
  return <span><b>{formatUserIdentifier(user)}</b></span>;
  
};

const _menuItems: ItemType[] = [
  { label: "Orders", key: "/orders", icon: <LocalMallOutlinedRounded /> },
  { label: "Line Items", key: "/line_items", icon: <LaundryOutlinedRounded /> },
  {
    label: "Schedules",
    key: "schedules", // never called directly, so not a path (cannot duplicate calendar child)
    icon: <EventNoteOutlinedRounded />,
    children: [
      { label: "Calendar", key: "/schedules" },
      { label: "Floor", key: "/schedules/view/today" },
    ],
  },
  { label: "Batches", key: "/batches", icon: <ConveyorBeltOutlinedRounded /> },
  { label: "Scans", key: "/scans", icon: <ShareLocationOutlinedRounded /> },
  { label: "Stores", key: "/stores", icon: <StorefrontOutlinedRounded /> },
  {
    label: "Insights",
    key: "/insights",
    icon: <InsightsRounded />,
    children: [
      { label: "Batches", key: "/insights/batches" },
      { label: "Demand", key: "/insights/demand" },
      { label: "Line Items", key: "/insights/line_items" },
      { label: "Orders", key: "/insights/orders" },
    ],
  },
  {
    label: "Reports",
    key: "/reports",
    icon: <LabProfileOutlinedRounded />,
    children: [
      {
        label: "Demand",
        key: "/demand",
        children: [
          { label: "Active Units to Print", key: "/reports/demand/active_print" },
          { label: "Bin", key: "/reports/demand/bin" },
          { label: "Canceled", key: "/reports/demand/canceled" },
          { label: "Out of Stock", key: "/reports/demand/oos" },
          { label: "Printed", key: "/reports/demand/printed" },
          { label: "Summary", key: "/reports/demand/summary" },
        ]
      },
      {
        label: "Production",
        key: "/production",
        children: [
          { label: "Allocations", key: "/reports/production/allocations/summary" },
          { label: "Blanks Pulled by Employees", key: "/reports/production/pull" },
          { label: "Completed Waypoint Counts", key: "/reports/production/waypoint" },
          { label: "Consumed Blank Counts", key: "/reports/production/blanks" },
          { label: "Pending Print Counts", key: "/reports/production/pending_print" },
          { label: "Printed Unit Counts", key: "/reports/production/print" },
          { label: "Store Progress Counts", key: "/reports/production/progress" },
        ]
      },
      {
        label: "Download Only",
        key: "/download",
        children: [
          { label: "Active/Pending Units", key: "/reports/download/line_item/active_pending" },
          { label: "Completed Waypoints", key: "/reports/download/scans/completed_waypoints" },
          { label: "Duplicates Orders", key: "/reports/download/order/duplicates" },
          { label: "Invalid Orders", key: "/reports/download/order/invalids" },
          { label: "Pending Prints", key: "/reports/download/line_item/pending_prints"},
          { label: "Print Waypoints", key: "/reports/download/scans/print_waypoints" },
        ]
      }
    ],
  },
  {
    label: "D365",
    key: "/d365",
    icon: <IssuesCloseOutlined />,
    children: [
      { label: "Product", key: "/d365/product" },
      { label: "Sync", key: "/d365/sync" },
    ]
  },
  { label: "Settings",
    key: "/settings",
    icon: <SettingsIcon />,
    children: [
      { label: "Timezone", key: "/settings/timezone" },
      /* Disabled until we can add security around the menu item */
      /*
      { label: "Facilities", key: "/settings/facilities" },
      { label: "Store Priority", key: "/settings/store_priority" },
      { label: "Batches", key: "/settings/batches" },
      */
    ]
  },
];
